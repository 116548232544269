import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  ModalBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
} from 'reactstrap';
import download from 'downloadjs';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import styled from 'styled-components';

import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import ModalContentContext from './ModalContent.Context';
import { formatBytes, formatDate, translateArray } from '../../utils';
import ContainerDatePicker from 'components/Container-Date-Picker';

const ModalContent = ({ accountId, taskId }) => {
  const {
    content,
    setContent,
    adminStatus,
    checklist,
    comments,
    files,
    setFiles,
    showMoreButton,
    setShowMoreButton,
  } = useContext(ModalContentContext);
  const sendCommentRef = useRef();

  const firebaseHelper = getFirebaseBackend();
  const { t: translate } = useTranslation();

  const deleteFile = (file, index) => {
    firebaseHelper.removeFile(file.fullPath).then(async () => {
      const newFiles = _.cloneDeep(files);
      newFiles.splice(index, 1);
      setFiles(newFiles);
      if (!newFiles.length) {
        const taskPath = {
          clientId: accountId,
          projectId: '1',
          id: taskId,
          taskId,
        };
        const taskData = await firebaseHelper.getTask(taskPath);
        await firebaseHelper.updateTask({
          ...taskData,
          ...taskPath,
          hasFiles: false,
        });
      }
    });
  };

  const downloadFile = async (file) => {
    const fileObj = await firebaseHelper.getFileObj(file.fullPath);
    download(fileObj, file.name, file.contentType);
  };

  const seeMore = () => {
    const descriptionElem = document.querySelector('#markdown-description');
    const buttonElem = document.querySelector('#show-more-box');

    descriptionElem.classList.toggle('show-more');
    buttonElem.classList.toggle('show-more');

    setShowMoreButton((prevState) => !prevState);
  };

  // Comment related functions
  const handleCommentKeyboard = async (event, id) => {
    const text = event.target.value;
    // Show Send Button if there's something typed on input
    text
      ? (sendCommentRef.current.style.display = 'flex')
      : (sendCommentRef.current.style.display = 'none');
    if (!text || event.keyCode !== 13 || event.shiftKey) return;

    const comment = {
      accountId,
      projectId: '1',
      columnId: id,
      id: await firebaseHelper.newCommentKey({
        accountId,
        id,
      }),
      by: await firebaseHelper.currentUser().uid,
      content: text,
    };
    event.target.value = '';
    handleCommentResize(event);
    await firebaseHelper.registerComment(comment);

    // After adding a comment, scroll down to show the comment
    const scrollElem = document.querySelector('.comment-scroll');
    scrollElem.scrollTop = scrollElem.scrollHeight - scrollElem.clientHeight;
  };

  const handleCommentResize = (event) => {
    const inputElem = event.target;

    // Auto Resizes the TextArea
    inputElem.style.height = 'auto';
    inputElem.style.height = (inputElem.scrollHeight < 150 ? inputElem.scrollHeight : 150) + 'px';
    inputElem.scrollTop = inputElem.scrollHeight;
    window.scrollTo(window.scrollLeft, inputElem.scrollTop + inputElem.scrollHeight);
  };

  const manualCommentResize = (elem) => {
    // Auto Resizes the TextArea
    elem.style.height = 'auto';
    elem.style.height = (elem.scrollHeight < 150 ? elem.scrollHeight : 150) + 'px';
    elem.scrollTop = elem.scrollHeight;
    window.scrollTo(window.scrollLeft, elem.scrollTop + elem.scrollHeight);
  };

  const sanitizedURLs = (string) => {
    // Sanitizes text before it is used on dangerouslySetInnerHTML and is transformed into DOM
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const newString = string.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });

    return newString;
  };

  const handleCommentClick = async (event, id) => {
    const inputElem = event.target.previousSibling;
    const text = inputElem.value;
    if (!text) return;

    const comment = {
      accountId,
      projectId: '1',
      columnId: id,
      id: await firebaseHelper.newCommentKey({
        accountId,
        id,
      }),
      by: await firebaseHelper.currentUser().uid,
      content: text,
    };
    inputElem.value = '';
    manualCommentResize(inputElem);
    await firebaseHelper.registerComment(comment);

    // After adding a comment, scroll down to show the comment
    const scrollElem = document.querySelector('.comment-scroll');
    scrollElem.scrollTop = scrollElem.scrollHeight - scrollElem.clientHeight;
  };

  const handleEditComment = async (comment, columnId, index, event) => {
    if (!(event.keyCode === 13 && !event.shiftKey)) return;
    event.preventDefault();

    const text = event.target.textContent;

    if (text) {
      const newComment = {
        ...comment,
        content: text,
        accountId,
        projectId: '1',
        columnId,
      };

      await firebaseHelper.updateComment(newComment);
      endEditComment(comment.id);
    } else {
      // If the text is erased as a whole it will be deleted
      endEditComment(comment.id);
      deleteComment(comment.id, taskId, index);
    }
  };

  const startEditComment = (commentId) => {
    const textElem = document.getElementById(`content-${commentId}`);
    const comment = comments.find((c) => c.id === commentId);

    if (textElem && comment) {
      textElem.innerHTML = comment.content;
      textElem.contentEditable = true;
      textElem.classList.add('com-box-content-edit');
      textElem.focus();
    }
  };

  const endEditComment = async (commentId) => {
    const textElem = document.getElementById(`content-${commentId}`);
    if (textElem) {
      textElem.contentEditable = false;
      textElem.classList.remove('com-box-content-edit');
      const comment = comments.find((c) => c.id === commentId);
      if (comment) {
        textElem.innerHTML = sanitizedURLs(comment.content);
      }
    }
  };

  const deleteComment = (commentId, columnId) => {
    const comment = {
      accountId,
      projectId: '1',
      columnId,
      commentId,
    };
    firebaseHelper.removeComment(comment);
  };

  // Checklist related functions
  const handleCheck = async (event, check, taskId) => {
    const checkElem = event.target;
    const newCheck = {
      ...check,
      accountId,
      projectId: '1',
      taskId,
      checked: checkElem.checked,
    };
    await firebaseHelper.updateCheck(newCheck);
  };

  const handleNewCheck = async (event, taskId) => {
    const content = event.target.value;
    if (!(content && event.keyCode === 13)) return;

    const newCheck = {
      id: await firebaseHelper.newCheckKey({
        accountId,
        projectId: '1',
        taskId,
      }),
      position: await firebaseHelper.getLastCheckPosition({
        accountId,
        projectId: '1',
        taskId,
      }),
      content,
      checked: false,
      accountId,
      projectId: '1',
      taskId,
    };

    event.target.value = '';

    firebaseHelper.registerCheck(newCheck).then(
      () => {},
      (error) => {
        console.error(error);
        event.target.value = content;
      },
    );
  };

  const handleNewDefaultCheck = async (taskId) => {
    const newCheck = {
      id: await firebaseHelper.newCheckKey({
        accountId,
        projectId: '1',
        taskId,
      }),
      position: await firebaseHelper.getLastCheckPosition({
        accountId,
        projectId: '1',
        taskId,
      }),
      content: translate('New item'),
      checked: false,
      accountId,
      projectId: '1',
      taskId,
    };

    firebaseHelper.registerCheck(newCheck);
  };

  const handleEditCheck = async (check, taskId, index, event) => {
    if (!(event.keyCode === 13 && !event.shiftKey)) return;
    event.preventDefault();

    const text = event.target.innerHTML;
    if (text) {
      const newCheck = {
        ...check,
        content: text,
        accountId,
        projectId: '1',
        taskId,
      };
      await firebaseHelper.updateCheck(newCheck);
      endEditCheck(check.id);
    } else {
      // If the text is erased as a whole it will be deleted
      endEditCheck(check.id);
      deleteCheck(check.id, taskId, index);
    }
  };

  const startEditCheck = (checkId) => {
    const allEditElems = document.querySelectorAll('[class*=com-box-content-edit]');
    const textElem = document.getElementById(`check-${checkId}`);
    // Loop through all comments with editing status and stop editing them
    allEditElems.forEach((elem) => {
      if (elem === textElem) return;
      elem.classList.remove('com-box-content-edit');
      elem.contentEditable = false;
    });
    // Uses the edit button as a toggle to the edit function
    textElem.contentEditable = textElem.classList.contains('com-box-content-edit') ? false : true;
    textElem.classList.toggle('com-box-content-edit');
  };

  const endEditCheck = async (checkId) => {
    const textElem = document.getElementById(`check-${checkId}`);
    textElem.contentEditable = false;
    textElem.classList.remove('com-box-content-edit');
  };

  const handleEditingStatus = (event) => {
    const textElem = event.target;

    if (textElem.classList.contains('com-box-content-edit')) {
      event.preventDefault();
    }
  };

  const deleteCheck = (taskId, checkId, index) => {
    const check = {
      accountId,
      projectId: '1',
      taskId,
      checkId,
    };
    firebaseHelper.removeCheck(check);
  };

  const deleteChecklist = async (taskId) => {
    const check = {
      accountId,
      projectId: '1',
      taskId,
    };
    firebaseHelper.removeChecklist(check).then(async () => {
      setContent((prevState) => ({ ...prevState, hasChecklist: false }));
    });
  };

  return (
    <ModalBody className="d-flex flex-row p-0 justify-content-between">
      <div className="d-flex flex-column my-3 mx-3 ps-3" style={{ width: '62%', maxWidth: '62%' }}>
        <div>
          <h3>{content?.name}</h3>
        </div>
        <div className="d-flex gap-3 mt-2" style={{ color: 'rgba(173, 181, 189, 1)' }}>
          <div className="d-flex flex-column align-items-start gap-1 justify-content-end">
            <LabelDate>{translate('Start date')}</LabelDate>
            <ContainerDatePicker date={content?.dateBegin} id={content?.id} isDateBegin anyDate />
          </div>
          <div className="d-flex flex-column align-items-start gap-1 justify-content-end">
            <LabelDate>{translate('Delivery date')}</LabelDate>
            <ContainerDatePicker
              date={content?.dateDelivery}
              id={content?.id}
              isDateDelivery
              anyDate
            />
          </div>
          <div className="d-flex flex-column align-items-start gap-1 justify-content-end">
            <LabelDate>{translate('Implementation date')}</LabelDate>
            <ContainerDatePicker
              date={content?.dateImplementation}
              id={content?.id}
              isDateImplementation
              anyDate
            />
          </div>
          <div className="d-flex flex-column align-items-start gap-1 justify-content-end">
            <LabelDate>{translate('Pillars')}</LabelDate>
            <div className="d-flex align-items-center justify-content-center">
              <i className="bx bx-purchase-tag-alt fs-4" />
              <span
                className="d-flex flex-wrap mx-2 align-items-center justify-content-center"
                style={{ minHeight: '2.438rem' }}
              >
                {content?.foundations?.map(translateArray)?.toLocaleString()}
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div className="modal-task-content">
          {content?.description && (
            <div
              id="markdown-description"
              className="container markdown-description show-more"
              style={{ overflowWrap: 'break-word' }}
            >
              <MDEditor.Markdown
                ref={(ref) => {
                  if (ref) {
                    ref.mdp.current.innerHTML = ref?.mdp.current.innerHTML.replaceAll(
                      /<img\s+[^>]*src="([^"]+)"[^>]*>/g,
                      '<a href="$1" target="_blank">$&</a>',
                    );
                  }
                }}
                source={content?.description}
                rehypePlugins={[[rehypeSanitize]]}
                linkTarget="_blank"
              />
              <div id="show-more-box" className="markdown-description-fade">
                <button className="show-more-button" onClick={seeMore}>
                  <i className={`bx bx-chevron-${showMoreButton ? 'up' : 'down'}`} />
                  {translate(`Show ${showMoreButton ? 'Less' : 'More'}`) + '...'}
                </button>
              </div>
            </div>
          )}
          <div className="mt-3">
            {content?.hasChecklist && (
              <div>
                <h4 className="d-flex align-items-center">
                  <i className="bx bx-list-ul" style={{ color: 'rgba(173, 181, 189, 1)' }} />
                  <p style={{ margin: '0', marginLeft: '.5em' }}>
                    {translate('Checklist') + ' ' + content.checkStats}
                  </p>
                  <UncontrolledDropdown className="com-message-option">
                    <DropdownToggle caret tag="span">
                      <button style={{ backgroundColor: 'white', border: 'none' }}>
                        <i className="bx bx-dots-horizontal-rounded" />
                      </button>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleNewDefaultCheck(content.id)}>
                        <i className="bx bx-plus" style={{ fontSize: '1.25em', color: 'black' }} />{' '}
                        <span style={{ fontSize: '1.1em', color: 'black' }}>
                          {translate('New item')}
                        </span>
                      </DropdownItem>
                      <DropdownItem onClick={() => deleteChecklist(content.id)}>
                        <i className="bx bx-trash" style={{ fontSize: '1.25em', color: 'red' }} />{' '}
                        <span style={{ fontSize: '1.1em', color: 'red' }}>
                          {translate('Delete checklist')}
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </h4>
                <hr style={{ margin: '1em 0 1em 0' }} />
                <div className="d-flex flex-column ms-1">
                  {checklist?.map((check, index) => (
                    <div
                      style={{ lineHeight: '1.1', paddingRight: '1em' }}
                      key={`check-${check.id}`}
                      className="check-div d-flex flex-row align-items-center"
                    >
                      <label key={`check-${index}`} htmlFor={check.id} className="custom-checklist">
                        <input
                          type="checkbox"
                          name={check.id}
                          id={check.id}
                          defaultChecked={check.checked}
                          defaultValue={check.checked}
                          onChange={(event) => handleCheck(event, check, content.id)}
                        />
                        <span
                          id={`check-${check.id}`}
                          onClick={(event) => handleEditingStatus(event)}
                          onKeyDown={(event) => handleEditCheck(check, content.id, index, event)}
                        >
                          {check.content}
                        </span>
                      </label>
                      <UncontrolledDropdown
                        direction={check.content.length > 20 ? 'start' : 'end'}
                        className="com-message-option"
                      >
                        <DropdownToggle className="com-message-option" caret tag="span">
                          <button style={{ backgroundColor: 'white' }} className="chk-list-option">
                            <i className="bx bx-dots-horizontal-rounded" />
                          </button>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => startEditCheck(check.id)}>
                            <i
                              className="bx bx-pencil"
                              style={{ fontSize: '1.25em', color: 'black' }}
                            />{' '}
                            <span style={{ fontSize: '1.1em', color: 'black' }}>
                              {translate('Edit item')}
                            </span>
                          </DropdownItem>
                          <DropdownItem onClick={() => deleteCheck(content.id, check.id, index)}>
                            <i
                              className="bx bxs-eraser"
                              style={{ fontSize: '1.25em', color: 'black' }}
                            />{' '}
                            <span style={{ fontSize: '1.1em', color: 'black' }}>
                              {translate('Delete item')}
                            </span>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  ))}
                  <label htmlFor="new-check" className="new-check">
                    <i className="bx bx-plus" />
                    <input
                      type="text"
                      placeholder={translate('Add item on checklist')}
                      autoComplete="off"
                      name="new-check"
                      id="new-check"
                      onKeyUp={(event) => handleNewCheck(event, content.id)}
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="mt-3">
            {content?.hasFiles && (
              <div>
                <h4 className="d-flex align-items-center">
                  <i className="bx bx-paperclip" style={{ color: 'rgba(173, 181, 189, 1)' }} />
                  <p style={{ margin: '0', marginLeft: '.5em' }}>{translate('Files')}</p>
                </h4>
                <hr style={{ margin: '1em 0 1em 0' }} />
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{translate('File Name')}</th>
                      <th scope="col">{translate('Uploaded')}</th>
                      <th scope="col">{translate('Size')}</th>
                      <th scope="col"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {files?.map((file, index) => (
                      <tr key={index} style={{ cursor: 'auto' }}>
                        <td>{file.name}</td>
                        <td>{new Date(file.timeCreated).toLocaleDateString()}</td>
                        <td>{formatBytes(file.size)}</td>
                        <td>
                          {
                            <div className="d-flex gap-1">
                              <Button
                                className="rounded-circle py-1 px-2"
                                style={{
                                  backgroundColor: '#F2F2F2',
                                  border: 'none',
                                }}
                                onClick={() => downloadFile(file)}
                              >
                                <i className="bx bx-download" style={{ color: 'black' }} />
                              </Button>
                              <Button
                                className="rounded-circle py-1 px-2"
                                style={{
                                  backgroundColor: '#F2F2F2',
                                  border: 'none',
                                }}
                                onClick={() => deleteFile(file, index)}
                              >
                                <i className="bx bx-trash" style={{ color: 'black' }} />
                              </Button>
                            </div>
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="comment-wrapper" style={{ width: '38%' }}>
        <div className="comment-title">
          <i className="bx bx-message-square-detail" />
          <h5>{translate('Comments')}</h5>
        </div>
        <div className="comment-scroll">
          {comments?.map((comment, index) => (
            <div className="comment-message" key={comment.id}>
              <div className={`comment-box ${comment.self ? 'self-box' : 'others-box'}`}>
                <p className="com-box-title">{comment.byName}</p>
                <p
                  className="com-box-content"
                  id={`content-${comment.id}`}
                  onKeyDown={(event) => handleEditComment(comment, content.id, index, event)}
                  dangerouslySetInnerHTML={{
                    __html: sanitizedURLs(comment.content),
                  }}
                />
                <div className="com-box-date">
                  <i className="bx bx-time-five" />
                  <p>{formatDate(new Date(comment.createdAt), translate('date-enUS'), true)}</p>
                </div>
              </div>
              {comment.self && (
                <UncontrolledDropdown className="com-message-option">
                  <DropdownToggle className="com-message-option" caret tag="span">
                    <button>
                      <i className="bx bx-dots-vertical-rounded" />
                    </button>
                  </DropdownToggle>
                  <DropdownMenu className="menu-shift">
                    <DropdownItem onClick={() => startEditComment(comment.id)}>
                      {translate('Edit')}
                    </DropdownItem>
                    <DropdownItem onClick={() => deleteComment(comment.id, content.id, index)}>
                      {translate('Delete')}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {adminStatus === 'Super Admin' && !comment.self && (
                <UncontrolledDropdown className="com-message-option">
                  <DropdownToggle className="com-message-option" caret tag="span">
                    <button>
                      <i className="bx bx-dots-vertical-rounded" />
                    </button>
                  </DropdownToggle>
                  <DropdownMenu className="menu-shift">
                    <DropdownItem onClick={() => deleteComment(comment.id, content.id, index)}>
                      {translate('Delete')}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
          ))}
          {!comments?.length && (
            <div className="no-comment">
              {translate('There are no comments on the task yet...')}
            </div>
          )}
        </div>
        <div className="comment-input">
          <textarea
            placeholder={translate('Type Comment')}
            onKeyUp={(event) => handleCommentKeyboard(event, content.id)}
            onChange={handleCommentResize}
            rows={1}
          />
          <button
            ref={sendCommentRef}
            style={{ display: 'none' }}
            onClick={(event) => handleCommentClick(event, content.id)}
          >
            {translate('Send')}
            <i className="bx bxs-send" />
          </button>
        </div>
      </div>
    </ModalBody>
  );
};

const UncontrolledDropdownStyled = styled(UncontrolledDropdown)`
  input:focus {
    border: 1px solid #555;
  }
  input:focus-within {
    border: 1px solid #555;
  }
`;

const LabelDate = styled.span`
  color: #333;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

ModalContent.propTypes = {
  accountId: PropTypes.string,
  taskId: PropTypes.string,
};

export default ModalContent;
